import * as styles from './style.css'
import { ProfileCard, type ProfileCardProps } from '~/components/ui/cards/ProfileCard'

type Props = {
  className?: string
  infos: ProfileCardProps[]
  col?: '4'
}

export const ProfileCardList = ({ className, infos, col = '4' }: Props) => {
  return (
    <div
      className={[styles.profileCardList, styles[`profileCardList__col${col}`], className].filter(Boolean).join(' ')}
    >
      <ul className={styles.profileCardList_items}>
        {infos.map((info, i) => (
          <li key={i} className={styles.profileCardList_item}>
            <ProfileCard {...info} />
          </li>
        ))}
      </ul>
    </div>
  )
}
