import * as styles from './LeftColumn.css'
import { LayoutInner } from '~/components/ui/layouts/LayoutInner'
import { MainTitle } from '~/components/ui/typographies/MainTitle'
import { BaseButton } from '~/components/ui/buttons/BaseButton'
import { SectionTitle } from '~/components/ui/typographies/SectionTitle'
import imgTopArrowTop from '~/assets/img/img-top-arrow-top.svg'
import imgTopRocket from '~/assets/img/img-top-rocket.svg'
import imgLogoEiicon from '~/assets/img/img-logo-eiicon.svg'
import imgMainVisual from '~/assets/img/img-main-visual.png'
import imgLogoTokyoSuteam from '~/assets/img/img-logo-tokyo-suteam.png'

export const LeftColumn = () => {
  return (
    <div className={styles.homeLeftColumn}>
      <div className={styles.homeMainVisual}>
        <div className={styles.homeMainVisual_wrapper}>
          <LayoutInner className={styles.homeLeftColumn_inner} size="large">
            <div className={styles.homeMainVisual_container}>
              <MainTitle
                className={styles.homeMainVisualTitle}
                title={
                  <>
                    Both funding and business scale are necessary
                    <br />
                    <em className={styles.homeMainVisualTitle_emphasis}>for startup.</em>
                  </>
                }
              />

              <p className={styles.homeMainVisual_lead}>資金調達もオープンイノベーションもスタートアップには必要だ。</p>

              <div className={styles.homeMainVisualRegister}>
                <BaseButton
                  className={styles.homeMainVisualRegister_button}
                  url="/register"
                  text="新規会員登録(無料)"
                />
              </div>
            </div>
          </LayoutInner>
        </div>

        <figure className={styles.homeMainVisualImage}>
          <img src={imgMainVisual} alt="Fund Raising, Open Innovation" className={styles.homeMainVisualImage_image} />
        </figure>
      </div>

      <section className={styles.homeConcept}>
        <LayoutInner className={styles.homeLeftColumn_inner} size="large">
          <div className={styles.homeConcept_container}>
            <SectionTitle
              className={styles.homeConcept_title}
              title="スタートアップの発射台としての要素を"
              size="small"
            />

            <p className={styles.homeConcept_paragraph}>
              「スタートアップ」とは、1人の起業家から加速度的に成長する企業を指す。
              <br />
              企業の成長を加速させるために必要な要素は「資金」・「ビジネスモデル」、そして「機会」。まず「何をするのか」を伝える力を磨き、仲間を得て資金を獲得する。そしてスケールさせるタイミングでのパートナーとの出会い。
              <br />
              最初の言葉の磨き込みからエンジンとなる資金調達の支援、事業成長過程における共創支援までオープンイノベーション支援のプロであるeiiconがサポートします。
            </p>

            <div className={styles.homeConceptDescription}>
              <div className={styles.homeConceptDescriptionIllustration}>
                <div className={styles.homeConceptDescriptionIllustration_background} />
                <div className={styles.homeConceptDescriptionIllustration_moon} />
                <div className={styles.homeConceptDescriptionIllustration_stars}>
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                  <i className={styles.homeConceptDescriptionIllustration_star} />
                </div>
                <img src={imgTopArrowTop} alt="" className={styles.homeConceptDescriptionIllustration_arrowTop} />
                <img src={imgTopRocket} alt="" className={styles.homeConceptDescriptionIllustration_rocket} />
              </div>

              <div className={styles.homeConceptDescriptionList}>
                <ul className={styles.homeConceptDescriptionList_items}>
                  <li className={styles.homeConceptDescriptionList_item}>
                    <div className={styles.homeConceptDescriptionList_contents}>
                      <div className={styles.homeConceptDescriptionList_box}>
                        <span className={styles.homeConceptDescriptionList_subTitle}>目的地への道筋</span>
                        <span className={styles.homeConceptDescriptionListTitle}>
                          <span className={styles.homeConceptDescriptionListTitle_text}>
                            オープンイノベーションによる
                          </span>
                          <span className={styles.homeConceptDescriptionListTitle_text}>事業スケール</span>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className={styles.homeConceptDescriptionList_item}>
                    <div className={styles.homeConceptDescriptionList_contents}>
                      <div className={styles.homeConceptDescriptionList_box}>
                        <span className={styles.homeConceptDescriptionList_subTitle}>発射に耐えうる設計</span>
                        <span className={styles.homeConceptDescriptionListTitle}>
                          <span className={styles.homeConceptDescriptionListTitle_text}>事業を成功させる</span>
                          <span className={styles.homeConceptDescriptionListTitle_text}>ビジネスメンタリング</span>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className={styles.homeConceptDescriptionList_item}>
                    <div className={styles.homeConceptDescriptionList_contents}>
                      <div className={styles.homeConceptDescriptionList_box}>
                        <span className={styles.homeConceptDescriptionList_subTitle}>発射するための燃料</span>
                        <span className={styles.homeConceptDescriptionListTitle}>
                          <span className={styles.homeConceptDescriptionListTitle_text}>資金調達</span>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className={styles.homeConceptDescriptionBottom}>
                <div className={styles.homeConceptDescriptionBottomIcon}>
                  <i className={styles.homeConceptDescriptionBottomIcon_icon} />
                </div>

                <div className={styles.homeConceptDescriptionBottomMessage}>
                  <div className={styles.homeConceptDescriptionBottomMessage_container}>
                    <p className={styles.homeConceptDescriptionBottomMessage_paragraph}>
                      スタートアップが目標にたどり着くための
                      <br />
                      発射台すべてを用意
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutInner>
      </section>

      <section className={styles.homeCollaboration}>
        <LayoutInner className={styles.homeLeftColumn_inner} size="large">
          <div className={styles.homeCollaboration_container}>
            <h2 className={styles.homeCollaborationTitle}>
              <span className={styles.homeCollaborationTitle_items}>
                <span className={styles.homeCollaborationTitle_item}>
                  <img
                    src={imgLogoTokyoSuteam}
                    alt="TOKYO SUTEAM"
                    className={styles.homeCollaborationTitle_image}
                    width={172}
                  />
                </span>
                <span className={styles.homeCollaborationTitle_item}>
                  <span className={styles.homeCollaborationTitle_text}>×</span>
                </span>
                <span className={styles.homeCollaborationTitle_item}>
                  <img src={imgLogoEiicon} alt="eiicon" className={styles.homeCollaborationTitle_image} width={105} />
                </span>
              </span>
            </h2>

            <p className={styles.homeCollaboration_paragraph}>
              日本再興のカギのひとつ、スタートアップ。
              <br />
              走り出せたスタートアップが歩を緩めず、非連続の成長を続けるためにはオープンイノベーションが有効である。
              <br />
              だからこそ、我々eiiconが「資金調達」と「オープンイノベーション」でスタートアップの成長支援を実施する。「未来を切り開く10×10×10のビジョンの実現」に向け東京都と異次元のスタートアップ創出を目指します。
            </p>
          </div>
        </LayoutInner>
      </section>
    </div>
  )
}
