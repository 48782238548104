import { type MetaFunction } from '@remix-run/node'
import { getMetadata } from '~/utils/meta'
import * as styles from './route.css'
import { LayoutPageWrapper } from '~/components/ui/layouts/LayoutPageWrapper'
import { LeftColumn } from './LeftColumn'
import { RightColumn } from './RightColumn'

export const meta: MetaFunction = (args) => {
  return getMetadata({
    args,
  })
}

export default function Index() {
  return (
    <LayoutPageWrapper className={styles.home} isTopNoSpace isBottomNoSpace>
      <div className={styles.home_container}>
        <div className={[styles.home_contents, styles.home_contents__left].filter(Boolean).join(' ')}>
          <LeftColumn />
        </div>
        <div className={[styles.home_contents, styles.home_contents__right].filter(Boolean).join(' ')}>
          <RightColumn />
        </div>
      </div>
    </LayoutPageWrapper>
  )
}
