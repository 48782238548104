import * as styles from './RightColumn.css'
import { LayoutInner } from '~/components/ui/layouts/LayoutInner'
import { EventCardList } from '~/components/ui/lists/EventCardList'
import { SectionTitle } from '~/components/ui/typographies/SectionTitle'
import { ProfileCardList } from '~/components/ui/lists/ProfileCardList'
import { ThumbnailList } from '~/components/ui/lists/ThumbnailList'
import imgTopCommit001 from '~/assets/img/img-top-commit-001.png'
import imgTopInvestor001 from '~/assets/img/img-top-investor-001.jpg'
import imgTopInvestor002 from '~/assets/img/img-top-investor-002.jpg'
import imgTopInvestor003 from '~/assets/img/img-top-investor-003.png'
import imgTopInvestor004 from '~/assets/img/img-top-investor-004.jpg'
import imgTopInvestor005 from '~/assets/img/img-top-investor-005.jpg'
import imgTopInvestor006 from '~/assets/img/img-top-investor-006.jpg'
import imgTopInvestor007 from '~/assets/img/img-top-investor-007.jpg'
import imgTopInvestor008 from '~/assets/img/img-top-investor-008.jpg'
import imgTopInvestor009 from '~/assets/img/img-top-investor-009.jpg'
import imgTopValue001 from '~/assets/img/img-top-value-001.png'
import imgTopValue002 from '~/assets/img/img-top-value-002.png'
import imgTopValue003 from '~/assets/img/img-top-value-003.png'
import imgTopValue004 from '~/assets/img/img-top-value-004.png'
import imgTopValue006 from '~/assets/img/img-top-value-006.png'
import imgTopValue007 from '~/assets/img/img-top-value-007.png'
import imgTopValue008 from '~/assets/img/img-top-value-008.png'
import imgTopValue009 from '~/assets/img/img-top-value-009.png'
import imgTopScalers003 from '~/assets/img/img-top-scalers-003.png'
import imgLogoKeio from '~/assets/img/img-logo-keio.jpg'
import imgLogoSnHoki from '~/assets/img/img-logo-sn-hoki.jpg'
import imgLogoJrEastStartup from '~/assets/img/img-logo-jr-east-startup.jpg'
import imgLogoSumitomoLife from '~/assets/img/img-logo-sumitomo-life.jpg'
import imgLogoMtgVentures from '~/assets/img/img-logo-mtg-ventures.jpg'
import imgLogoKddi from '~/assets/img/img-logo-kddi.jpg'
import imgLogoGenesiaVentures from '~/assets/img/img-logo-genesia-ventures.jpg'
import imgLogoStartPass from '~/assets/img/img-logo-start-pass.jpg'
import imgLogoSpiralInnovationPartners from '~/assets/img/img-logo-spiral-innovation-partners.jpg'
import imgLogoLeo from '~/assets/img/img-logo-leo.jpg'
import imgLogoMitsuiSumitomoConstruction from '~/assets/img/img-logo-mitsui-sumitomo-construction.jpg'

export const RightColumn = () => {
  return (
    <div className={styles.homeRightColumn}>
      <section className={styles.homeCommit}>
        <LayoutInner className={styles.homeRightColumn_inner} size="large">
          <div className={styles.homeCommit_container}>
            <EventCardList
              className={styles.homeCommit_events}
              infos={[
                {
                  tagInfos: [
                    {
                      name: '資金調達ピッチ',
                    },
                  ],
                  featureTitle: 'The Commit',
                  title: (
                    <>
                      オープンイノベーションのeiiconが仕掛ける資金調達プログラム。未来を切り拓くスタートアップの挑戦を本気で支援します。
                      <br />
                      ※本プログラムは東京都採択事業です
                    </>
                  ),
                  titleTag: 'h2',
                  bottomButtonInfo: {
                    text: '応募はこちら',
                    url: '/projects/4/proposals/3',
                  },
                  backgroundImage: {
                    src: imgTopCommit001,
                  },
                },
              ]}
            />

            <SectionTitle className={styles.homeCommit_contentsTitle} title="Investor" titleTag="h3" />

            <ProfileCardList
              className={styles.homeCommit_profileList}
              infos={[
                {
                  profileImage: {
                    src: imgTopInvestor001,
                  },
                  title: '岡 洋',
                  description: (
                    <>
                      Spiral Innovation Partners
                      <br />
                      代表パートナー
                    </>
                  ),
                },
                {
                  profileImage: {
                    src: imgTopInvestor002,
                  },
                  title: '河合 将文',
                  description: '株式会社ジェネシア・ベンチャーズ パートナー',
                },
                {
                  profileImage: {
                    src: imgTopInvestor003,
                  },
                  title: '常盤木 龍治',
                  description: 'パラレルキャリアエバンジェリスト株式会社EBILAB取締役ファウンダー CTO CSO',
                },
                {
                  profileImage: {
                    src: imgTopInvestor004,
                  },
                  title: '篠原 豊',
                  description: (
                    <>
                      エバーコネクト株式会社
                      <br />
                      代表取締役
                    </>
                  ),
                },
                {
                  profileImage: {
                    src: imgTopInvestor005,
                  },
                  title: '粟生 万琴',
                  description: '株式会社LEO 代表取締役 武蔵野大学アントレプレナーシップ学部 教授',
                },
                {
                  profileImage: {
                    src: imgTopInvestor006,
                  },
                  title: '守屋 実',
                  description: '新規事業家',
                },
                {
                  profileImage: {
                    src: imgTopInvestor007,
                  },
                  title: '藤田 豪',
                  description: (
                    <>
                      株式会社MTG Ventures
                      <br />
                      代表取締役
                    </>
                  ),
                },
                {
                  profileImage: {
                    src: imgTopInvestor008,
                  },
                  title: '小原 聖誉',
                  description: (
                    <>
                      株式会社StartPass
                      <br />
                      代表取締役CEO
                    </>
                  ),
                },
                {
                  profileImage: {
                    src: imgTopInvestor009,
                  },
                  title: '中馬 和彦',
                  description: (
                    <>
                      KDDI株式会社
                      <br />
                      事業創造本部 副本部長
                    </>
                  ),
                },
              ]}
            />

            <ThumbnailList
              className={styles.homeCommit_thumbnailList}
              infos={[
                {
                  src: imgLogoMtgVentures,
                  alt: 'MTG Ventures',
                  width: 194,
                },
                {
                  src: imgLogoKddi,
                  alt: 'KDDI',
                  width: 194,
                },
                {
                  src: imgLogoGenesiaVentures,
                  alt: 'Genesia Ventures',
                  width: 194,
                },
                {
                  src: imgLogoStartPass,
                  alt: 'START PASS',
                  width: 194,
                },
                {
                  src: imgLogoSpiralInnovationPartners,
                  alt: 'Spiral Innovation Partners',
                  width: 194,
                },
                {
                  src: imgLogoLeo,
                  alt: 'LEO',
                  width: 194,
                },
              ]}
            />

            <div className={styles.homeValue}>
              <div className={styles.homeValue_container}>
                <div className={styles.homeValueTitle}>
                  <div className={styles.homeValueTitle_container}>
                    <h3 className={styles.homeValueTitle_text}>「資金調達」にコミットする4つのバリュー</h3>
                  </div>
                </div>

                <div className={styles.homeValue_contents}>
                  <div className={styles.homeValueList}>
                    <ul className={styles.homeValueList_items}>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue001} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            ビジネス
                            <br />
                            メンタリング
                          </h4>
                        </div>
                      </li>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue002} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            ピッチ
                            <br />
                            ブラッシュアップ
                            <br />
                            ワーク
                          </h4>
                        </div>
                      </li>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue003} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            資金調達
                            <br />
                            ピッチ
                          </h4>
                        </div>
                      </li>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue004} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            メディア/
                            <br />
                            プレス発信
                          </h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutInner>
      </section>

      <section className={styles.homeScalers}>
        <LayoutInner className={styles.homeRightColumn_inner} size="large">
          <div className={styles.homeScalers_container}>
            <EventCardList
              className={styles.homeScalers_events}
              infos={[
                {
                  color: 'primary',
                  tagInfos: [
                    {
                      name: 'オープンイノベーションプログラム',
                    },
                  ],
                  featureTitle: 'The Scale',
                  featureTitleTag: '#教育',
                  title: (
                    <>
                      オープンイノベーションを自社戦略に組み込み、飛躍的な成長を促すThe Scale。
                      <br />
                      第3回は「教育」。
                    </>
                  ),
                  bottomButtonInfo: {
                    text: '応募はこちら',
                    url: '/projects/6/proposals/6',
                    // isDisabled: true,
                  },
                  backgroundImage: {
                    src: imgTopScalers003,
                  },
                },
              ]}
            />

            <SectionTitle className={styles.homeScalers_contentsTitle} title="参画プレイヤー" titleTag="h3" />

            <ThumbnailList
              className={styles.homeScalers_thumbnailList}
              infos={[
                {
                  src: imgLogoKeio,
                  alt: '京王',
                  width: 194,
                },
                {
                  src: imgLogoSnHoki,
                  alt: '新日本法規出版株式会社',
                  width: 194,
                },
                {
                  src: imgLogoJrEastStartup,
                  alt: 'JR東日本スタートアップ株式会社',
                  width: 194,
                },
                {
                  src: imgLogoSumitomoLife,
                  alt: '住友生命',
                  width: 194,
                },
                {
                  src: imgLogoMitsuiSumitomoConstruction,
                  alt: '三井住友建設',
                  width: 194,
                },
              ]}
            />

            <p className={styles.homeScalers_note}>※他参画パートナー調整中</p>

            <div className={styles.homeValue}>
              <div className={styles.homeValue_container}>
                <div className={styles.homeValueTitle}>
                  <div className={styles.homeValueTitle_container}>
                    <h3 className={styles.homeValueTitle_text}>事業を”スケール”する5つのバリュー</h3>
                  </div>
                </div>

                <div className={styles.homeValue_contents}>
                  <div className={[styles.homeValueList, styles.homeValueList__col5].filter(Boolean).join(' ')}>
                    <ul className={styles.homeValueList_items}>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue006} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            共創
                            <br />
                            ピッチ
                          </h4>
                        </div>
                      </li>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue007} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            共創
                            <br />
                            マッチング
                          </h4>
                        </div>
                      </li>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue008} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            インキュベーション
                            <br />
                            メンタリング
                          </h4>
                        </div>
                      </li>
                      <li className={styles.homeValueList_item}>
                        <div className={styles.homeValueList_contents}>
                          <figure className={styles.homeValueListThumbnail}>
                            <img src={imgTopValue009} alt="" className={styles.homeValueListThumbnail_image} />
                          </figure>

                          <h4 className={styles.homeValueList_title}>
                            メディア/
                            <br />
                            プレス発信
                          </h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutInner>
      </section>
    </div>
  )
}
