import { type ReactNode } from 'react'
import * as styles from './style.css'
import { UserIcon } from '~/components/ui/icons/UserIcon'

export type ProfileCardProps = {
  className?: string
  profileImage?: {
    src: string
    alt?: string
  }
  title: ReactNode
  titleTag?: keyof JSX.IntrinsicElements
  description?: ReactNode
}

export const ProfileCard = ({ className, profileImage, title, titleTag, description }: ProfileCardProps) => {
  const Title = titleTag ?? 'h4'

  return (
    <div className={[styles.profileCard, className].filter(Boolean).join(' ')}>
      <div className={styles.profileCard_container}>
        <div className={styles.profileCardIcon}>
          <UserIcon
            className={styles.profileCardIcon_icon}
            size="large"
            src={profileImage?.src}
            alt={profileImage?.alt}
          />
        </div>

        <div className={styles.profileCardTitle}>
          <Title className={styles.profileCardTitle_text}>{title}</Title>
        </div>

        {description && (
          <div className={styles.profileCardDescription}>
            <p className={styles.profileCardDescription_paragraph}>{description}</p>
          </div>
        )}
      </div>
    </div>
  )
}
