import { type ReactNode } from 'react'
import * as styles from './style.css'

type Props = {
  className?: string
  title: ReactNode
  titleTag?: keyof JSX.IntrinsicElements
  color?: 'default' | 'sub'
  size?: 'small'
}

export const SectionTitle = ({ className, title, titleTag, color = 'default', size }: Props) => {
  const Title = titleTag ?? 'h2'

  return (
    <div
      className={[
        styles.sectionTitle,
        styles[`sectionTitle__${color}`],
        size && styles[`sectionTitle__${size}`],
        className,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <Title className={styles.sectionTitle_text}>{title}</Title>
    </div>
  )
}
