import * as styles from './style.css'

type Props = {
  className?: string
  infos: {
    src: string
    alt?: string
    width?: number
    height?: number
  }[]
}

export const ThumbnailList = ({ className, infos }: Props) => {
  return (
    <div className={[styles.thumbnailList, className].filter(Boolean).join(' ')}>
      <ul className={styles.thumbnailList_items}>
        {infos.map((info, i) => (
          <li key={i} className={styles.thumbnailList_item}>
            <figure className={styles.thumbnailList_thumbnail}>
              <img src={info.src} alt={info.alt} width={info.width} height={info.height} />
            </figure>
          </li>
        ))}
      </ul>
    </div>
  )
}
